<template>
    <v-overlay :value="loading">
        <v-progress-circular indeterminate
                             size="64"
                             :z-index="300">
        </v-progress-circular>
    </v-overlay>
</template>

<script>
    export default {
        name: "custom-loading",

        computed: {
            loading() {
                return this.$store.state.loading.loading;
            }
        },

        data: () => ({
        }),
        methods: {

        }
    };
</script>
<style>
   
</style>