export default [
    {
        path: 'vaccinations',
        component: () => import('../views/vaccinations/Vaccinations.vue'),
        children: [
            {
                path: '/',
                name: 'Vaccinations',
                component: () => import('../views/vaccinations/vaccinationsList/VaccinationsList.vue'),
                meta: { breadcrumb: 'قائمة التطعيمات' },
            },
            {
                path: '/add-vaccine',
                name: 'AddVaccine',
                component: () => import('../views/vaccinations/addVaccine/AddVaccine.vue'),
                meta: { breadcrumb: 'إضافة تطعيم' },
            },
        ]
    }
]