<template src="./Footer.html">
   
</template>

<script>

    export default {
        name: 'Footer',

        components: {
        },

        created() {
        },


        computed: {
            
            
            items() {
                return [
                    {
                        title: "التطعيمات",
                        icon: "mdi-home",
                        path: "vaccinations",
                    },
                    {
                        title: "إفادة تطعيم",
                        icon: "mdi-file-document-multiple-outline",
                        path: "certificates",
                    },
                    {
                        title: "الإستعلام عن مسحة",
                        icon: "mdi-test-tube",
                        path: "pcr-result",
                    },
                ]
            }
            
           
        },

        data: () => ({
            links: [
                { link: 'https://www.facebook.com/NCDC.LY/', icon: 'mdi-facebook', title: 'Facebook' },
                { link: 'https://twitter.com/NcdcLy', icon: 'mdi-twitter', title: 'Twitter' },
                { link: 'https://www.instagram.com/ncdc.ly/', icon: 'mdi-instagram', title: 'Instagram' },
            ],

           
            
        }),

        methods: {
            goTo(item) {
                window.open(item.link, 'blank')
            }
        }
    };
</script>

<style scoped lnag="scss">
   
</style>
