const allowedFileTypes = ['image/jpeg', 'image/jpg', 'image/png']

export default {

    nameRules: [
        v => !!v || 'يرجى ادخال الاسم',
        v => (v && v.length > 10) || 'الاسم يجب ان يكون 10 احرف على الاقل',
    ],

    emailRules: [
        v => !!v || 'يجب ادحال البريد الالكتروني',
        v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'البريد الالكتروني غير صحبح',
    ],

    checkValueRules: [v => !!v || 'يجب تعبئة الحقل'],

    checkBoxGroupRules: [v => v.length < 1 || 'يجب اختيار حقل'],

    uploadFileRules: [
        v => !!v || 'برجاء رفع ملف',
        v => (v && v.size < 9000000) || 'حجم الملف لا يتجاوز 2 ميجابايت',
        v => (v && allowedFileTypes.includes(v.type)) || 'نوع الملف PNG او JPG او JPEG',
    ],

    empCodeRules: [
        v => !!v || 'يرجى ادخال الرقم الوظيفي',
        v => /^\d{4}$/.test(v) || 'يجب ادخال اربع ارقام'
    ],

    checkLanPhoneNo: [
        v => !v || (v && /^\d{4}$/.test(v)) || 'يجب ادخال اربع ارقام'
    ],

    checkPhoneNo: [
        v => !!v || 'يرجى ادخال رقم الهاتف',
        v => /^\d{7}$/.test(v) || 'يجب ادخال 7 ارقام'
    ],

    nationalNoRules: [
        v => !!v || 'يرجى ادخال الرقم الوطني',
        v => (v && v.length == 12) || 'الرقم الوطني غير صحيح',
        v => (v && (v[0] == 1 || v[0] == 2)) || 'الرقم الوطني غير صحيح',
        v => (v && v.substring(1, 5) > 1800) || 'الرقم الوطني غير صحيح',
    ]

}