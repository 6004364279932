export default [
    {
        path: 'pcr-result',
        component: () => import('../views/pcrResult/PcrResult.vue'),
        children: [
            {
                path: '/',
                name: 'pcr-result',
                component: () => import('../views/pcrResult/pcrResultList/PcrResultList.vue'),
                meta: { breadcrumb: 'نتائج تحليل PCR' },
            },
        ]
    }
]