export default {
    dataEntry: 0,
    active: 1,
    rejected: 5,

    vaccinationStatus: {
        dataEntry: 0,
        active: 1,
        rejected: 5
    },

    pcrResult: {
        negative: 1,
        positive: 2,
        retest: 3
    }
}