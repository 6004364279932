<template src="./Topbar.html">
   
</template>

<script>

    export default {
        name: 'Topbar',

        components: {
        },

        created() {
        },


        computed: {
            person() {
                return this.$store.state.auth.person;
            },

            appbarHeight() {
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs': case 'sm': return 60;
                    default: return 100;
                }
            },

            route() {
                return this.$route.meta.breadcrumb;
            },
            items() {
                return [
                    {
                        title: "الرئيسية",
                        icon: "mdi-home",
                        path: "home",
                        show: true,
                    },
                    {
                        title: "التطعيمات",
                        icon: "mdi-needle",
                        path: "vaccinations",
                        show: true,
                    },
                    {
                        title: "إفادة تطعيم",
                        icon: "mdi-file-document-multiple-outline",
                        path: "certificates",
                        show: true,
                    },
                    {
                        title: "الإستعلام عن مسحة",
                        icon: "mdi-test-tube",
                        path: "pcr-result",
                        show: true,
                    },
                    {
                        title: "الشهادات الصحية",
                        icon: "mdi-test-tube",
                        path: "hc-result",
                        show: true,
                    },
                    {
                        title: "مراكز التطعيم",
                        icon: "mdi-hospital-building",
                        path: "health-centers",
                        show: true,
                    },
                    {
                        title: "تغيير كلمة المرور",
                        icon: "mdi-account-key-outline",
                        path: "change-password",
                        show: (this.person && this.$vuetify.breakpoint.smAndDown),
                    },
                ]
            }
        },

        data: () => ({
           
            menu: false,

            infoItems: [
                {
                    title: "تغيير كلمة المرور",
                    icon: "mdi-account-key-outline",
                    path: "change-password",
                    show: true
                },
            ],
        }),

        methods: {
            toggleMenu() {
                this.menu = !this.menu;
            },
            login() {
                this.$router.push("/sign-form")
            },
            logout() {
                this.$store.commit("auth/clearPerson");
                window.location.href = '/';
            }
        }
    };
</script>

<style scoped lnag="scss">
    .user-menu-header {
        background-color: #b40001;
    }

    .v-menu__content {
        border-radius: 8px;
    }

    .route {
        padding-right: 0px !important;
        font-size: 18px;
    }
</style>
