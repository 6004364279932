
export default {

    getPersonCertificateInfo() {
        return axios({
            url: `certificates/get-person-certificate-info`,
            method: 'GET',
        })
    },

    getCertificateInfo(cipherCertificateId) {
        return axios({
            url: `certificates/get-certificate-info?cipherCertificateId=${cipherCertificateId}`,
            method: 'GET',
        })
    },
};