<template>
    <v-snackbar v-model="show"
                :color="color"
                outlined
                rounded="pill"
                right
                :timeout="timeout"
                multiLine>
        <v-icon :color="color">{{icon}}</v-icon>
        {{ message }}

        <template v-slot:action="{ attrs }">
            <v-btn color="error"
                   text
                   v-bind="attrs"
                   @click="show = false"
                   icon>
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
    export default {
        name: "custom-snakbar",

        computed: {
            message() {
                return this.$store.state.snackbar.message;
            },
            icon() {
                return this.$store.state.snackbar.icon;
            },
            color() {
                return this.$store.state.snackbar.type;
            },
            show: {
                get() {
                    return this.$store.state.snackbar.show;
                },
                set() {
                    this.$store.commit("snackbar/hide");
                }
            }
        },

        data: () => ({
            timeout: 4000,
        }),
        methods: {

        }
    };
</script>