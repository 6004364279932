const state = {
    show: false,
    message: "",
    icon: "",
    type: ""
};

const mutations = {
    show: (state, { message, icon, type }) =>
        ([state.message, state.icon, state.type, state.show] = [message, icon, type, true]),
    hide: state => (state.show = false)
};

export default {
    namespaced: true,
    state,
    mutations
};
