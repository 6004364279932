import Vue from 'vue'
import authService from "./authService.js";
import certificatesService from "./certificatesService.js";
import lookupsService from "./lookupsService.js";
import vaccinationsService from "./vaccinationsService.js";
import pcrResultService from "./pcrResultService.js";
import hcResultService from "./hcResultService.js";
import homeService from "./homeService.js";


export default {
    ...authService,
    ...certificatesService,
    ...lookupsService,
    ...vaccinationsService,
    ...pcrResultService,
    ...hcResultService,
    ...homeService,
};