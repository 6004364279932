
export default {
    
    getHealthCentersLookup() {
        return axios({
            url: `lookups/get-health-centers`,
            method: 'GET'
        })
    },

    getMunicipalitiesLookup() {
        return axios({
            url: `lookups/get-municipalities`,
            method: 'GET'
        })
    },

    getVaccinesLookup() {
        return axios({
            url: `lookups/get-vaccines`,
            method: 'GET'
        })
    },
    getNationalitiesLookup() {
        return axios({
            url: `lookups/get-nationalities`,
            method: 'GET'
        })
    }

};