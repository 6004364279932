"use strict";

import Vue from 'vue';
import axios from "axios";
import store from '../store';


let config = {
    //baseURL: "http://localhost:64747/api",
    baseURL: "https://vac.ncdc.gov.ly/api",
    // timeout: 60 * 1000, // Timeout
    // withCr/edentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
    function (config) {
        store.commit("loading/start");
        let accessToken = store.state.auth.accessToken
        config.headers = { 'Authorization': 'Bearer ' + accessToken };
        return config;
    },
    function (error) {
        store.commit("loading/stop");
        return Promise.reject(error);
    }
);

_axios.interceptors.response.use(
    function (response) {
        store.commit("loading/stop");
        return response;
    },
    function (error) {
        store.commit("loading/stop");
        if (!error.response) {
            store.commit("snackbar/show", { message: "الرجاء التحقق من اتصال الشبكة", icon: "mdi-wifi-off", type: "error" })
            return Promise.reject(error);
        }

        const status = error.response.status;
        const errorMessage = error.response.data.msg;

        if (status === 400 || status === 404) {
            store.commit("snackbar/show", { message: errorMessage, icon: "mdi-info-outline", type: "error" })
        }

        // check user authentication
        if (status === 401) {
            store.commit("auth/clearPerson");
            window.location.href = '/sign-form';
            store.commit("snackbar/show", { message: errorMessage, icon: "mdi-account-cancel-outline", type: "error" })
        }
        // check server error
        if (status >= 500) {
            store.commit("snackbar/show", { message: errorMessage, icon: "mdi-server-off", type: "error" })
        }

        return Promise.reject(error);
    }
);

Plugin.install = function (Vue, options) {
    Vue.axios = _axios;
    window.axios = _axios;
    Object.defineProperties(Vue.prototype, {
        axios: {
            get() {
                return _axios;
            }
        },
        $axios: {
            get() {
                return _axios;
            }
        },
    });
};

Vue.use(Plugin)

export default Plugin;
