import Vue from 'vue'
import Vuex from 'vuex'
import auth from "./modules/auth"
import snackbar from "./modules/snackbar"
import loading from "./modules/loading"
import lookup from "./modules/lookup"

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {
        auth,
        snackbar,
        loading,
        lookup,
    }
})
