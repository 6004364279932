import httpService from "../../services";

const state = {
    nationalities: [],
    vaccines: [],
    healthCenters: [],
    municipalities: [],
};

const getters = {
    getHealthCenterByMunicipality: (state) => (municipalityId) => {
        if (municipalityId != null)
            return state.healthCenters.filter(healthCenter => healthCenter.municipalityId === municipalityId)
        else
            return state.healthCenters
    },
};

const mutations = {
    setNationalities: (state, nationalities) => (state.nationalities = nationalities),
    setVaccines: (state, vaccines) => (state.vaccines = vaccines),
    setHealthCenters: (state, healthCenters) => (state.healthCenters = healthCenters),
    setMunicipalities: (state, municipalities) => (state.municipalities = municipalities),
};

const actions = {
    async getNationalities({ state, commit }) {
        try {
            if (state.nationalities.length === 0) {
                const response = await httpService.getNationalitiesLookup();
                const nationalities = response.data.nationalities;
                commit("setNationalities", nationalities)
            }
        } catch (err) {
            return
        }
    },
    async getVaccines({ state, commit }) {
        try {
            if (state.vaccines.length === 0) {
                const response = await httpService.getVaccinesLookup();
                const vaccines = response.data.vaccines;
               
                commit("setVaccines", vaccines)
            }
        } catch (err) {
            return
        }
    },
    async getHealthCenters({ state, commit }) {
        try {
            if (state.healthCenters.length === 0) {
                const response = await httpService.getHealthCentersLookup();
                const healthCenters = response.data.healthCenters;
                commit("setHealthCenters", healthCenters)
            }
        } catch (err) {
            return
        }
    },

    async getMunicipalities({ state, commit }) {
        try {
            if (state.municipalities.length === 0) {
                const response = await httpService.getMunicipalitiesLookup();
                const municipalities = response.data.municipalities;
                commit("setMunicipalities", municipalities)
            }
        } catch (err) {
            return
        }
    },

};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};