import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../views/layout/Layout.vue'
import store from '../store'
import vaccinationsRoute from './vaccinationsRoute.js'
import pcrResultRoute from './pcrResultRoute.js'
import hcResultRoute from './hcResultRoute.js'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Layout',
        redirect: "home",
        component: Layout,
        children: [
            {
                path: 'home',
                name: 'Home',
                meta: { breadcrumb: 'الرئيسية' },
                component: () => import('../views/home/Home.vue')
            },                       
            {
                path: 'certificates',
                name: 'Certificates',
                meta: { breadcrumb: 'إفادة تطعيم' },
                component: () => import('../views/certificates/Certificates.vue')
            },
            {
                path: 'health-centers',
                name: 'HealthCenters',
                meta: { breadcrumb: 'مراكز التطعيم' },
                component: () => import('../views/healthCenters/HealthCenters.vue')
            },
            {
                path: 'change-password',
                name: 'ChangePassword',
                meta: { breadcrumb: 'تغيير كلمة المرور' },
                component: () => import('../views/changePassword/ChangePassword.vue')
            },
            {
                path: 'forget-password',
                name: 'ForgetPassword',
                meta: { breadcrumb: 'نسيت كلمة المرور' },
                component: () => import('../views/forgetPassword/ForgetPassword.vue')
            },
            {
                path: '/sign-form',
                name: 'SignForm',
                meta: { breadcrumb: 'تسجيل الدخول' },
                component: () => import('../views/signForm/SignForm.vue')
            },
            ...vaccinationsRoute,
            ...pcrResultRoute,
            ...hcResultRoute,
            {
                path: '/privacy-policy',
                name: 'PrivacyPolicy',
                meta: { breadcrumb: 'سياسات الخصوصية' },
                component: () => import('../views/privacyPolicy/PrivacyPolicy.vue')
            },
            {
                path: '/terms-of-service',
                name: 'Terms',
                meta: { breadcrumb: 'سياسات الاستخدام' },
                component: () => import('../views/terms/Terms.vue')
            },
        ],
    },
    {
        path: '/print-certificate/:cipherCertificateId',
        name: 'PrintCertificate',
        meta: { breadcrumb: 'إفادة تطعيم' },
        component: () => import('../views/printCertificate/PrintCertificate.vue'),
    },
    { path: '*', redirect: '/' }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})


router.beforeEach((to, from, next) => {
    const publicPagesName = ['SignForm', 'Home', 'HealthCenters', 'PrintCertificate', 'ForgetPassword', 'PrivacyPolicy', 'Terms'];
    const authRequired = !publicPagesName.includes(to.name);
    if (authRequired && (store.state.auth.accessToken == '' || store.state.auth.accessToken == undefined)) {
        store.state.auth.lastRoute = to.name;
        return next('/sign-form');
    }
    next();
})

router.afterEach(() => {
    window.scrollTo(0, 0);
})

export default router
