import httpService from "../../services";

const state = {
    accessToken: localStorage.getItem('accessToken'),
    person: (localStorage.getItem('person-info') == null) ? null : JSON.parse(localStorage.getItem('person-info')),
    lastRoute: null,
};

const getters = {

};

const mutations = {
    setToken: (state, accessToken) => {
        localStorage.setItem('accessToken', accessToken);
        state.accessToken = accessToken;
    },

    setPerson: (state, person) => {
        state.person = {
            fullName: person.fullName,
            nationalNo: person.nationalNo,
            passportNo: person.passportNo,
        };
        localStorage.setItem('person-info', JSON.stringify(person));
    },

    clearPerson: (state) => {
        state.accessToken = '';
        state.person = null;
        localStorage.removeItem('accessToken');
        localStorage.removeItem('person-info');
    },

};

const actions = {
    async login({ state, commit }, loginInfo) {
        if (state.accessToken == '' || state.accessToken == undefined) {
            await httpService.login(loginInfo)
                .then(res => {
                    const accessToken = res.data.loginResult.accessToken;
                    let person = {
                        fullName: res.data.loginResult.fullName,
                        nationalNo: res.data.loginResult.nationalNo,
                        passportNo: res.data.loginResult.passportNo,

                    }
                    commit("setToken", accessToken);
                    commit("setPerson", person);
                    return res;
                })
                .catch(err => {
                    return Promise.reject(err);
                })
        } else {
            return;
        }
    },

    async autoLogin({ state, commit }, loginResult) {
        if (state.accessToken == '' || state.accessToken == undefined) {
            const accessToken = loginResult.accessToken;
            let person = {
                fullName: loginResult.fullName,
                nationalNo: loginResult.nationalNo,
                passportNo: loginResult.passportNo,

            }
            commit("setToken", accessToken);
            commit("setPerson", person);
        }
    },
};


const auth = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

export default auth;