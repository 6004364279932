<template src="./Layout.html">
</template>

<script>
    import CustomSnackbar from "../../components/CustomSnackbar.vue";
    import CustomLoading from "../../components/CustomLoading.vue";

    import MyTopbar from "./topbar/Topbar.vue";
    import MyFooter from "./footer/Footer.vue";

    export default {
        name: 'Layout',
        components: {
            MyTopbar,
            MyFooter,
            CustomSnackbar,
            CustomLoading,
        },

        data: () => ({
            showFooter: true,
            

        }),

        mounted() {
        },


        methods: {
            
        }
    }
</script>

<style lnag="scss">
    /* @import '../../scss/variables.scss';*/
    *, .v-application--wrap,
    .v-application .text-md-body-1,
    .v-application .text-md-body-2,
    .v-application .display-2,
    .v-application .display-1,
    .v-application .title,
    .v-application .headline,
    .title,
    .v-application .text-h1,
    .v-application .text-h2,
    .v-application .text-h3,
    .v-application .text-h4,
    .v-application .text-h5,
    .v-application .text-h6,
    .v-application .subtitle-2,
    .v-application .subtitle-1,
    .v-application .caption,
    .v-application .body-2,
    .v-application .body-1 {
        font-family: 'almarai' !important;
    }

    ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        border-radius: 20px;
    }

    ::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 20px;
    }

    ::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 20px;
    }

    .container {
        padding: 0;
    }

    .div-container {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .v-footer .v-sheet.v-card:not(.v-sheet--outlined) {
        width: 100%;
    }

    .v-footer a {
        text-decoration: none;
    }

    .v-row {
        margin: 0 !important;
    }

    @media print {
        .v-footer, .v-app-bar, .v-snack {
            display: none !important;
        }

        .water-mark {
            margin: 0;
        }
    }

    a {
        text-decoration: none;
    }

    /*.v-application--wrap {
        overflow-x: scroll;
    }*/

    /*.border-left {
        border-left: 1px solid #c7c3c3;
    }*/

</style>