
export default {
    login(loginInfo) {
        return axios({ url: 'auth/login', data: loginInfo, method: 'POST' })
    },
    
    changePassword(passInfo) {
        return axios({ url: 'auth/change-password', data: passInfo, method: 'PUT' })
    },


    signup(signupInfo) {
        return axios({ url: 'auth/signup', data: signupInfo, method: 'POST' })
    },

    verifyPassowrd(passowrdInfo) {
        return axios({ url: 'auth/verify-passowrd', data: passowrdInfo, method: 'POST' })
    },

    resendPassword(passowrdInfo) {
        return axios({ url: 'auth/resend-password', data: passowrdInfo, method: 'POST' })
    },

    forgetPassword(personInfo) {
        return axios({ url: 'auth/forget-password', data: personInfo, method: 'POST' })
    }

};