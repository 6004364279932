export default [
    {
        path: 'hc-result',
        component: () => import('../views/hcResult/HcResult.vue'),
        children: [
            {
                path: '/',
                name: 'hc-result',
                component: () => import('../views/hcResult/hcResultList/HcResultList.vue'),
                meta: { breadcrumb: 'الشهادات الصحية' },
            },
        ]
    }
]