<template>
    <v-app :class="{'smAndDown': $vuetify.breakpoint.smAndDown}">
        <router-view></router-view>
    </v-app>
</template>

<script>

    export default {
        name: 'App',

        components: {
        },

        data: () => ({
            //
        }),
    };
</script>

<style lang="scss">
    .grecaptcha-badge{
        display: none;
    }
</style>
