export default {

    getVaccinations(pageNo) {
        return axios({
            url: `vaccinations/get-vaccinations?pageNo=${pageNo}`,
            method: 'GET'
        })
    },
    addNewVaccine(newVaccine) {
        return axios({ url: 'vaccinations/new-vaccine', data: newVaccine, method: 'POST' })
    },
};