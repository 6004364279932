import statusClass from '../utils/status';

function resolveCommas(number) {
    if (isNaN(number))
        return '';
    else return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function pcrColor(result) {

    let val = '';
    switch (result) {
        case statusClass.pcrResult.negative: val = 'success'; break;
        case statusClass.pcrResult.positive: val = 'error'; break;
        case statusClass.pcrResult.retest: val = 'warning'; break;
        default: val = '';
    }

    return val;
}

function pcrResult(result) {

    let val = '';
    switch (result) {
        case statusClass.pcrResult.negative: val = 'سالب'; break;
        case statusClass.pcrResult.positive: val = 'موجب'; break;
        case statusClass.pcrResult.retest: val = 'اعادة التحليل'; break;
        default: val = '-';
    }

    return val;
}

function status(status) {

    let val = '';
    switch (status) {
        case 0: val = 'قيد الإجراء'; break;
        case 1: val = 'مفعل'; break;
        case 4: val = 'معتمد'; break;
        case 5: val = 'مرفوض'; break;
        default: val = '-';
    }

    return val;
}

function statusColor(status) {

    let val = '';
    switch (status) {
        case 0: val = ''; break;
        case 1: val = 'success'; break;
        case 4: val = 'success'; break;
        case 5: val = 'error'; break;
        default: val = '-';
    }

    return val;
}
function vaccinationStatus(status) {

    let val = '';
    switch (status) {
        case statusClass.vaccinationStatus.active: val = 'معتمد'; break;
        case statusClass.vaccinationStatus.dataEntry: val = 'قيد الإجراء'; break;
        case statusClass.vaccinationStatus.rejected: val = 'مرفوض'; break;
        default: val = '-';
    }

    return val;
}

function vaccinationColor(status) {

    let val = '';
    switch (status) {
        case statusClass.vaccinationStatus.active: val = 'success'; break;
        case statusClass.vaccinationStatus.dataEntry: val = 'warning'; break;
        case statusClass.vaccinationStatus.rejected: val = 'error'; break;
        default: val = '';
    }

    return val;
}

function vaccineNo(index) {

    let val = '';
    switch (index) {
        case 0: val = '1st'; break;
        case 1: val = '2nd'; break;
        case 2: val = 'booster1'; break;
        case 3: val = 'booster2'; break;
        default: val = '-';
    }

    return val;
}

export default {
    install(Vue) {
        Vue.filter('pcrColor', pcrColor);
        Vue.filter('pcrResult', pcrResult);
        Vue.filter('numberCommaFilter', resolveCommas);
        Vue.filter('statusColor', statusColor);
        Vue.filter('status', status);
        Vue.filter('vaccinationStatus', vaccinationStatus);
        Vue.filter('vaccinationColor', vaccinationColor);
        Vue.filter('vaccineNo', vaccineNo);
    }
}