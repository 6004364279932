import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import './assets/fonts/stylesheet.css';
import http from './services/index'
import validation from './utils/validations';
import helpers from './utils/helpers';
import status from './utils/status';
import filters from './utils/filters';
import types from './utils/types';
import './scss/responsive.scss';
import { VueReCaptcha } from 'vue-recaptcha-v3';

Vue.config.productionTip = false

Vue.use(VueReCaptcha, { siteKey: '6LerQs4cAAAAAE-FDvH0_7EkZYyoN6zGHaAMSRPz' });
Vue.use(filters);

Vue.prototype.$validation = validation;
Vue.prototype.$http = http;
Vue.prototype.$helpers = helpers;
Vue.prototype.$status = status;
Vue.prototype.$types = types;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
